.font-link {
  font-family: 'Montserrat', normal;
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.playlist .info {
  width: 70%;
  margin: 133px auto 0;
  text-align: center;
}


.info .track-name {
  font-family: 'Montserrat', sans-serif;
  font-size: 40px;
  color: #fff;
  text-align: center;
  width: 100%;
}


.trackList {
  width: 85%;
  margin: 20px auto 20px;
  list-style: none;
  padding: 0px;
}

.trackList li {
  width: 100%;
  float: left;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 0.73rem;
  border-bottom: 1px solid #0b2b60;
  padding: 8px 8px
}

.trackList li .remove {
  width: 24px;
  height: 24px;
  float: right;
  margin: 0 0 0 15px;
  cursor: pointer;
}

.trackList li .play {
  width: 24px;
  height: 24px;
  float: right;
  margin: 0 0 0 15px;
  cursor: pointer;
}

.player-loading {
  width: 100%;
}

.trackList li .track-name {
  float: left;
}

.trackList li .re-search {
  width: 24px;
  height: 24px;
  float: right;
  margin: 0 0 0 15px;
  cursor: pointer;
}

.trackList li .re-search img {
  transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transition: all .2s ease-in;
}

.trackList li .re-search:hover img {
  transform: rotate(-360deg);
  -webkit-transform: rotate(-360deg);
  -ms-transform: rotate(-360deg);
  transition: all .5s ease-in;
}


/* Tooltip */
.tooltip.center {
  position: relative;
}

.tooltip.center:before, .tooltip.center:after {
  opacity: 0;
  z-index: 98;
  -moz-transition: opacity 300ms, visibility 0ms linear 300ms;
  -o-transition: opacity 300ms, visibility 0ms linear 300ms;
  -webkit-transition: opacity 300ms, visibility 0ms linear;
  -webkit-transition-delay: 0s, 300ms;
  transition: opacity 300ms, visibility 0ms linear 300ms;
  pointer-events: none;
}